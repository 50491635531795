import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";
import PsContext from "../../../context";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import StepMenu from "../stepMenu";
import TabMenu from "./tabMenu";
import { capitalizeFirst, upperCase } from "../../../utils";
import axios from "axios";
import { ServiceUrl } from "../../../utils/serviceUrl";
import { toast } from "react-hot-toast";
import { Spin } from "antd";
import { Link } from "react-router-dom";

const CourseApply = (props) => {
  const context = useContext(PsContext);

  const [activeTab, setActiveTab] = useState(null);
  const [loader, setLoader] = useState(false);
  const [validated, setValidated] = useState(false);
  const [applicant, setApplicant] = useState([]);

  const [courseList, setCourseList] = useState([]);
  const [appliedCourses, setAppliedCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState([]);

  const [paymentLink, setPaymentLink] = useState([]);


  useEffect(() => {
    if (context.settingValue("admission_has_ug_regular") == "1")
      setActiveTab("regular");
    else if (context.settingValue("admission_has_ug_self") == "1")
      setActiveTab("self");

    loadApplicantInfo();
  }, []);

  useEffect(() => {
    getCourseSuggestion();
  }, [activeTab]);

  const getApplicant = (fieldName) => {
    try {
      if (applicant && applicant[fieldName]) return applicant[fieldName];
    } catch (er) { }
  };

  const loadApplicantInfo = () => {
    try {
      setLoader(true);
      setApplicant([]);
      var form = new FormData();
      form.append("i__apiCode", context.api);
      axios.post(ServiceUrl.UG.APPLICANT_MIN_INFO, form).then((res) => {
        if (res["data"].status == "1") {
          let d = res["data"].data;

          setApplicant(d);
          setLoader(false);
        } else {
          toast.error(res["data"].message || "error");
          setLoader(false);
        }
      });
    } catch (error) {
      setLoader(false);
    }
  };

  const getCourseSuggestion = () => {
    try {
      setLoader(true);
      setCourseList([]);
      setSelectedCourse([]);
      setAppliedCourses([]);
      var form = new FormData();
      form.append("i__apiCode", context.user.api_code);
      form.append("i__courseType", activeTab);
      axios.post(ServiceUrl.UG.COURSE_FOR_ME, form).then((res) => {
        if (res["data"].status == "1") {
          setCourseList(res["data"].data);
          setAppliedCourses(res["data"].applied_course);
        } else {
          toast.error(res["data"].message || "Error");
        }
        setLoader(false);
      });
    } catch (error) {
      setLoader(false);
    }
  };

  const checkCourseApplied = (courseId) => {
    try {
      let index = appliedCourses.findIndex(
        (item) => item.course_id == courseId
      );
      return index > -1 ? true : false;
    } catch (error) {
      return false;
    }
  };
  const checkAnyCourseApplied = (selfORregular) => {

    var isApplied = false;
    appliedCourses.forEach(item => {
      var courseDate = courseList.find(obj => obj.course_id === item.id && obj.coursetype === selfORregular);
      if (courseDate) {
        isApplied = true;
      }
    })
    return isApplied;
    // console.log('test', appliedCourses);
    // return index > -1 ? true : false;

  };

  /** Common values from settings */
  const maxCourseSelection = () =>
    activeTab == "regular"
      ? context.settingValue("admission_ug_regular_max_course_selection")
      : context.settingValue("admission_ug_self_max_course_selection");

  const applicationFeeForScCandidates = () =>
    activeTab == "regular"
      ? context.settingValue("admission_ug_regular_application_scfee")
      : context.settingValue("admission_ug_self_application_scfee");

  const applicationFeeForOtherCandidates = () =>
    activeTab == "regular"
      ? context.settingValue("admission_ug_regular_application_fee")
      : context.settingValue("admission_ug_self_application_fee");

  const applicationFee = () => {
    let community = upperCase(applicant.community);
    return community == "SC" || community == "SCA" || community == "ST"
      ? applicationFeeForScCandidates()
      : applicationFeeForOtherCandidates();
  };

  const isScCandidate = () => {
    let community = upperCase(applicant.community);
    return community == "SC" || community == "SCA" || community == "ST"
      ? true
      : false;
  };

  const firstFreeForSc = () => false;

  /** Common values from settings */

  const handleCourseSelect = (course) => {
    let max = maxCourseSelection();
    let s = [...selectedCourse];
    if (s.length >= parseInt(max)) {
      toast.error(`Your are eligible to select ${max} courses only.`);
      return;
    }
    s.push(course);
    setSelectedCourse(s);
  };


  // const handleCourseSelect = (course) => {
  //   // check if user has already applied for a course in the same course type
  //   const alreadyApplied = selectedCourse.some(c => c.coursetype === course.coursetype);

  //   if (alreadyApplied) {
  //     toast.error("You have already applied for a course in this course type.");
  //     return;
  //   }

  //   let max = maxCourseSelection();
  //   let s = [...selectedCourse];
  //   if (s.length >= parseInt(max)) {
  //     toast.error(`Your are eligible to select ${max} courses only.`);
  //     return;
  //   }
  //   s.push(course);
  //   setSelectedCourse(s);
  // };


  const getSelectedCoursePreference = (courseId) => {
    try {
      let index = selectedCourse.findIndex((item) => item.id == courseId);
      return index > -1 ? index + 1 : false;
    } catch (er) {
      console.log(er);
      return false;
    }
  };

  const resetPreference = () => setSelectedCourse([]);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (selectedCourse.length < 1) {
      toast.error("Please select at least one course to apply");
      return;
    }
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      toast.error("Plese check all the fields filled");
      return;
    }

    if (
      !window.confirm(
        "Please check your course preference before processing. Do you want to proceed to next?"
      )
    )
      return;

    context.setLoader(true);

    axios.post(ServiceUrl.UG.COURSE_APPLY, new FormData(form)).then((res) => {
      if (res["data"].status == "1") {
        context.updateUser(res["data"].user);
        setPaymentLink(res["data"].data);
        if (res["data"].data && res["data"].data.access_code)
          document.getElementById("redirect").submit();
        else {
          context.setLoader(false);
          props.history.push("/");
        }
        //props.history.push("/");
      } else {
        toast.error(res["data"].message || "Error");
        context.setLoader(false);
      }
    });
  };

  const community = upperCase(getApplicant("community"));

  return (
    <>
      <div className="container mb-3">
        <Row>
          <Col md={{ span: 10, offset: 1 }}>
            <StepMenu activeKey="step4" />
            <Spin spinning={loader}>
              <TabMenu
                tabKey={activeTab}
                onTabChange={(key) => setActiveTab(key)}
              />

              <Card>
                <Card.Header>
                  <div className="float-start">
                    Name :{"  "}
                    <b className="text-uppercase">
                      {applicant.name} {applicant.initial}
                    </b>
                  </div>
                  <div className="float-end">
                    Registration No : {"  "}
                    <b>{applicant.user_code}</b>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="table-responsive">
                    <table className="table table-sm table-bordered">
                      <thead>
                        <tr className="bg-light">
                          <th>Name of the Program</th>
                          <th width="100">Medium</th>
                          <th width="100">Preference</th>
                          <th>
                            #
                            {selectedCourse.length > 0 && (
                              <Button
                                type="button"
                                size="xs"
                                className="ms-2"
                                variant="danger"
                                onClick={(e) => resetPreference()}
                              >
                                Reset Preference
                              </Button>
                            )}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {courseList.map((item, i) => {
                          return (
                            <tr
                              key={i}
                              className={
                                checkCourseApplied(item.id)
                                  ? "bg-primary-light"
                                  : getSelectedCoursePreference(item.id)
                                    ? "bg-success-light"
                                    : ""
                              }
                            >
                              <td>
                                {item.degreename} - {item.name}{" "}
                                {item.coursetype == "self" && "(SF)"}
                              </td>
                              <td>{capitalizeFirst(item.medium)}</td>
                              <td align="center">
                                {getSelectedCoursePreference(item.id)}
                              </td>
                              <td align="center">
                                {
                                  checkAnyCourseApplied(activeTab) && checkCourseApplied(item.id) ?
                                    'Already Applied'
                                    : checkAnyCourseApplied(activeTab) ? '' :

                                      (
                                        <>
                                          <Button
                                            type="button"
                                            size="sm"
                                            className="fs-8 "
                                            onClick={(e) => handleCourseSelect(item)}
                                          >
                                            Click to select
                                          </Button>
                                        </>
                                      )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  {selectedCourse && selectedCourse.length > 0 && (
                    <>
                      <Form action="" method="post" onSubmit={handleFormSubmit}>
                        <input
                          type="hidden"
                          name="i__userId"
                          value={context.user.user_id}
                        />
                        <input
                          type="hidden"
                          name="i__userCode"
                          value={context.user.user_code}
                        />
                        <input
                          type="hidden"
                          name="i__apiCode"
                          value={context.user.api_code}
                        />
                        <input
                          type="hidden"
                          name="i__courseType"
                          value={activeTab}
                        />
                        <input
                          type="hidden"
                          name="i__courseApply"
                          value={JSON.stringify(selectedCourse)}
                        />

                        <Row>
                          <Col md={10} className="text-end">
                            <label>Application Fee</label>
                          </Col>
                          <Col md={2}>
                            <Form.Control
                              type="text"
                              className="fw-bold text-end"
                              size="sm"
                              name="i__fee"
                              required
                              value={applicationFee()}
                            />
                          </Col>
                        </Row>
                        {isScCandidate() ? (
                          firstFreeForSc() && appliedCourses.length < 1 ? (
                            <Row className="mt-2">
                              <Col md={6}></Col>
                              <Col md={6} className="text-end">
                                <input
                                  type="radio"
                                  name="i__paymentMethod"
                                  value="direct"
                                  required
                                  defaultChecked={true}
                                  className="me-2"
                                />
                                No payment for SC/ST/SCA students (for first
                                application)
                              </Col>
                            </Row>
                          ) : parseFloat(applicationFee() == 0) ? (
                            <Row className="mt-2">
                              <Col md={6}></Col>
                              <Col md={6} className="text-end">
                                <input
                                  type="radio"
                                  name="i__paymentMethod"
                                  value="direct"
                                  required
                                  defaultChecked={true}
                                  className="me-2"
                                />
                                No payment for SC/ST/SCA students
                              </Col>
                            </Row>
                          ) : (
                            <Row className="mt-2">
                              <Col md={6}></Col>
                              <Col md={6} className="text-end">
                                <input
                                  type="radio"
                                  name="i__paymentMethod"
                                  value="online"
                                  required
                                  defaultChecked={true}
                                  className="me-2"
                                />
                                Online (Debit Card/ Credit Card/ Net Banking/
                                Wallet/ UPI)
                              </Col>
                            </Row>
                          )
                        ) : (
                          <Row className="mt-2">
                            <Col md={6}></Col>
                            <Col md={6} className="text-end">
                              <input
                                type="radio"
                                name="i__paymentMethod"
                                value="online"
                                required
                                defaultChecked={true}
                                className="me-2"
                              />
                              Online (Debit Card/ Credit Card/ Net Banking/
                              Wallet/ UPI)
                            </Col>
                          </Row>
                        )}
                        <Row className="mt-4">
                          <Col md={6}></Col>
                          <Col md={3}>
                            <Link
                              to="/"
                              className="btn btn-danger btn-sm w-100"
                            >
                              Cancel
                            </Link>
                          </Col>
                          <Col md={3}>
                            <Button
                              type="submit"
                              size="sm"
                              variant="success"
                              className="w-100"
                            >
                              Pay Now
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  )}
                </Card.Body>
              </Card>
            </Spin>
          </Col>
        </Row>
      </div>

      {paymentLink && paymentLink.access_code && (
        <form
          method="post"
          name="redirect"
          id="redirect"
          action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
        >
          <input type="hidden" name="encRequest" value={paymentLink.reqdata} />
          <input
            type="hidden"
            name="access_code"
            value={paymentLink.access_code}
          />
        </form>
      )}
    </>
  );
};

export default CourseApply;
