import React, { useState, useContext, useEffect } from "react";
import $ from "jquery";

import PsContext from "../../../context";
import { Card } from "react-bootstrap";
import axios from "axios";
import { ServiceUrl } from "../../../utils/serviceUrl";
import { Spin } from "antd";

const Announcement = (props) => {
  const context = useContext(PsContext);
  const [loader, setLoader] = useState(false);
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setLoader(true);
    axios.get(ServiceUrl.ADMISSION.ANNOUNCEMENTS).then((res) => {
      if (res["data"].status == "1") {
        setDataList(res["data"].data);
      }
      setLoader(false);
    });
  };

  const marqueeMouseOver = () => {
    document.getElementById("adm_top_marquee").stop();
  };

  const marqueeMouseOut = () => {
    document.getElementById("adm_top_marquee").start();
  };

  const renderHtmlText = () => {
    return dataList.map((item) => (
      <li style={{ display: "inline-block " }}>
        <div
          className="px-4"
          dangerouslySetInnerHTML={{ __html: item.html_text }}
        />
      </li>
    ));
  };

  return (
    <>
      <Card className="-btm">
        <Card.Header className="fw-bold">Announcements</Card.Header>
        <Card.Body style={{ minHeight: "60vh" }}>
          <Spin spinning={loader}>
            <marquee
              onMouseOver={marqueeMouseOver}
              onMouseOut={marqueeMouseOut}
              id="adm_top_marquee"
              direction="up"
              style={{ minHeight: "50vh" }}
              scrollamount="4"
            >
              <ul className="top_marquee px-0 py-0 mx-0 my-0">
                {renderHtmlText()}
              </ul>
            </marquee>
          </Spin>
        </Card.Body>
      </Card>
    </>
  );
};

export default Announcement;
